@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "tailwindcss/typography";

[x-cloak] { display: none !important; }

.post-content a {
    @apply text-blue-600;
    @apply visited:text-purple-600;
    @apply hover:text-blue-800;
    @apply hover:underline;
}

input[type="text"], input[type="email"], input[type="password"] {
    @apply w-full;
    @apply p-4;
    @apply text-sm;
    @apply border-gray-200;
    @apply border;
    @apply border-solid;
    @apply rounded-lg;
    @apply shadow-sm;
}

a {
    @apply hover:underline;
}

.submit {
    @apply inline-block;
    @apply px-5;
    @apply py-3;
    @apply mt-3;
    @apply text-sm;
    @apply font-medium;
    @apply text-white;
    @apply bg-blue-500;
    @apply rounded-lg;
    @apply w-full;
    @apply hover:bg-blue-700;
    @apply hover:cursor-pointer;
}

mark {
    @apply bg-indigo-600;
    @apply text-white;
}
